body {
  background-color: #F8F8F8;
  font-family: poppins, sans-serif;
}

.page-heading {
  color: #2774E9;
  font-size: 40px;
  font-weight: 500;
}

.register-card {
  background-color: #fff;
  border-radius: 10px;
  /* width: 400px; */
}

.form-heading {
  font-weight: 600;
  font-size: 24px;
  margin-top: 20px;
  color: rgba(0, 0, 0, 0.7);
}

.bold {
  font-weight: 600;
}

.form-subheading {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  line-height: 21px;
  margin: 0;
}

.text-field {
  box-sizing: border-box;
  width: 100%;
  border: none;
  border-bottom: 1.4px solid #D9D9D9;
  border-radius: 6px;
  font-size: 10px;
  line-height: 18px;
  height: 30px;
  /* -webkit-transition: 0.5s;
  transition: 0.5s; */
  outline: none;
}



.form-label {
  font-size: 12px;
  color: #2774E9;
  display: none;
}

.form-check-label {
  color: rgba(0, 0, 0, 0.4);
  font-size: 12px;
  line-height: 18px;
}

.form-btn {
  display: block;
  width: 80%;
  background-color: #2774E9;
  color: #fff;
  border: 1px solid #2774E9;
  border-radius: 6px;
}
button:disabled,
button[disabled]{
  border: 1px solid rgba(66,135,245,0.9);
  background-color: rgba(66,135,245,0.5);
  color: #fff;
}

.bg {
  background-image: url('./img/bg.jpg');
  border-radius: 20px;
  background-size: cover;
  background-position: center;
}

.transparent {
  background: rgba(255, 255, 255, 0.4);
  width: 40%;
  border-radius: 20px;
}

.inner-div {
  background: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  font-size: 10px;
}
.margin-large{
  margin-top: 100px;
  text-align: center;
}
small{
  font-size: 12px;
}
.link{
  font-size:10px;
  color: rgba(0, 0, 0, 0.5);
}